@import '../theming/themes/common-colors';

@mixin input-v2-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-input-v2 {
    &-icon {
      &:hover .ui-icon,
      &:focus .ui-icon {
        color: $primary;
      }
    }
  }
}
