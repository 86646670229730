@mixin unavailable-function-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, accent);

  .primary-stroke {
    stroke: $primary;
  }

  .secondary-stroke {
    stroke: $secondary;
  }

  .bpce-unavailablex-error-code {
    color: $primary;
  }
}
