@mixin breadcrumb-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-breadcrumbs {
    &-item {
      &-link {
        color: $primary;
      }
    }
  }
}
