@import '../theming/themes/common-colors';

@mixin select-v2-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-select-v2 {
    &:hover ui-icon,
    &:focus ui-icon {
      color: $primary;
    }

    &.bpce-select-v2-disabled {
      &:hover ui-icon {
        color: $g3;
      }
    }
  }
}
