@mixin tile-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-tile {
    &-selected {
      border-color: $primary !important;
    }

    &-pro {
      border-color: $primary;

      &::after {
        background-color: $primary;
      }
    }
  }
}
