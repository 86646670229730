@import '../../../common-sass/bpce-sass/index';
@import '../../theming/themes/common-colors';

@mixin calendar-base-view-theme($theme) {
  $primary: map-get($theme, primary);

  .ui-calendar-view {
    .ui-calendar-cell {
      .ui-calendar-cell-button {
        border-radius: $bpce-dim-xl * 0.5;
        color: $g1;

        &:focus,
        &:hover {
          background-color: $g5;
        }

        &.ui-calendar-cell-selected {
          color: $white;
          background-color: $primary !important;
        }

        &.ui-calendar-cell-disabled {
          color: $g3;
        }

        &.ui-calendar-cell-restricted {
          border-radius: 0;
          color: $g3;
          background-color: $g5;
          text-decoration: line-through;
        }
      }
    }
  }
}
