@import '../theming/themes/common-colors';

@mixin infobox-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, accent);

  .bpce-infobox {
    border: 1px solid $secondary;

    &-content {
      a {
        &:hover {
          color: $primary;
        }
      }
    }

    &-icon {
      color: $secondary;
    }

    &-action {
      color: $primary;
    }
  }
}
