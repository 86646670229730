@import '../theming/themes/common-colors';

@mixin input-phone-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-input-phone {
    &-prefix.bpce-inputlist-opened {
      .bpce-inputlist-header {
        .bpce-inputlist-icon-down {
          color: $primary;
        }
      }
    }
  }
}
