@mixin pictogram-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .ui-pictogram svg {
    .primary-fill {
      fill: $primary;
    }

    .secondary-fill {
      fill: $accent;
    }

    .primary-stroke {
      stroke: $primary;
    }

    .secondary-stroke {
      stroke: $accent;
    }
  }

  .ui-pictogram.ui-pictogram-reverse svg {
    .primary-fill,
    .secondary-fill {
      fill: $white;
    }

    .primary-stroke,
    .secondary-stroke {
      stroke: $white;
    }
  }
}
