@mixin right-menu-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-right-menu {
    &:hover {
      color: $primary;
    }

    &:hover &-icon {
      fill: $primary;
    }
  }
}
