@import '../../common-sass/bpce-sass';

@mixin button-more-theme($theme) {
  $primary: map-get($theme, primary);

  button.button-more-theme,
  a.button-more-theme > span {
    color: $primary;
    background-color: transparent;
  }
}
