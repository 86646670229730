@mixin action-cell-theme($theme) {
  $primary: map-get($theme, primary);

  ui-cell {
    .bpce-cell {
      &-content .bpce-cell-with-primary,
      &-icon {
        color: $primary;
      }
    }
  }
}
