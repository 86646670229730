@import '../theming/themes/common-colors';

@mixin alert-banner-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-alertbanner-content {
    a {
      &:hover {
        color: $primary;
      }
    }
  }
}
