@import '../theming/themes/common-colors';

@mixin select-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-rwd-header .icon-back {
    color: $primary;
  }

  li[uiSelectOption][aria-selected='true'] {
    color: $primary;
  }
}
