// BUILD VARS
$prefix: bpce !default;

// SPACINGS

$bpce-space-xxxs: 2px;
$bpce-space-xxs: 4px;
$bpce-space-xs: 8px;
$bpce-space-sm: $bpce-space-xs * 2; // 16
$bpce-space-md: $bpce-space-xs * 3; // 24
$bpce-space-lg: $bpce-space-xs * 4; // 32
$bpce-space-xl: $bpce-space-xs * 5; // 40
$bpce-space-xxl: $bpce-space-xs * 6; // 48
$bpce-space-xxxl: $bpce-space-xs * 7; // 56
$bpce-space-xxxxl: $bpce-space-xs * 8; // 64

// LAYOUT

$bpce-dim-xxxs: 2px;
$bpce-dim-xxs: 4px;
$bpce-dim-xs: 8px;
$bpce-dim-sm: $bpce-dim-xs * 2; // 16
$bpce-dim-md: $bpce-dim-xs * 3; // 24
$bpce-dim-lg: $bpce-dim-xs * 4; // 32
$bpce-dim-xl: $bpce-dim-xs * 5; // 40
$bpce-dim-xxl: $bpce-dim-xs * 6; // 48
$bpce-dim-xxxl: $bpce-dim-xs * 7; // 56
$bpce-dim-xxxxl: $bpce-dim-xs * 8; // 64

$bpce-breakpoints: (
  xs: 320px,
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1920px,
);

// MISC

$bpce-border-radius: 4px !default;

// SHADOWS
// See https://bpce.design/fondamentaux/ombres-et-elevations/
$bpce-shadow-xs: 0 0 4px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
$bpce-shadow-xs-hover: 0 4px 16px 0 rgba(0, 0, 0, 0.16), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
$bpce-shadow-sm: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
$bpce-shadow-md: 0 4px 24px 0 rgba(0, 0, 0, 0.14);
$bpce-focus-shadow: 0 0 2px 3px rgba(92, 139, 215, 0.8);
