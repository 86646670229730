@import '../theming/themes/common-colors';

@mixin input-big-amount-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-input-big-amount {
    &::placeholder,
    &:placeholder-shown {
      color: $primary;
    }

    &:placeholder-shown {
      opacity: 0.4;
    }
  }
}
