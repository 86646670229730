@import '../../theming/themes/common-colors';

@mixin loader-theme($theme) {
  $primary: map-get($theme, primary);
  $logo: map-get($theme, smallLogo);

  .bpce-loader svg circle {
    stroke: $primary;
  }

  .bpce-loader.bpce-loader-logo {
    background-image: url($logo);
  }
}
