@import '../theming/themes/common-colors';

$ui-ripple-color-opacity: 0.1;

@mixin button-theme($theme) {
  $primary: map-get($theme, primary);

  button,
  a {
    &.ui-button {
      &.primary {
        background-color: $primary;
        color: $white;
      }

      &.outline {
        background-color: $white;
        border: thin solid currentColor;
        color: $primary;
      }

      &.clear {
        background-color: $white;
        color: $primary;
      }

      &.tertiary {
        color: $primary;
      }

      &.quaternary {
        color: $g2;
      }
    }
  }
}

@mixin _ui-button-ripple-color($theme) {
  $primary: map-get($theme, primary);

  button,
  a {
    &.ui-button {
      &.primary .ui-ripple-element {
        background-color: rgba(lighten($primary, 80%), $ui-ripple-color-opacity);
      }
    }
  }
}
