@import '../../theming/themes/common-colors';

$ui-ripple-color-opacity: 0.1;

@mixin ui-ripple($theme) {
  $primary: map-get($theme, primary);

  // The host element of an ui-ripple directive should always have a position of "absolute" or
  // "relative" so that the ripples inside are correctly positioned relatively to the container.
  .ui-ripple {
    overflow: hidden;

    // By default, every ripple container should have position: relative in favor of creating an
    // easy API for developers using the UiRipple directive.
    position: relative;
    z-index: -1; // Ripple in the background
  }

  .ui-ripple.ui-ripple-unbounded {
    overflow: visible;
  }

  .ui-ripple-element {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
    transform: scale(0);
    background-color: rgba($primary, $ui-ripple-color-opacity);
  }

  button.ui-button {
    &.outline,
    &.clear {
      .ui-ripple-element {
        background-color: $g4;
      }
    }

    &.tertiary,
    &.quaternary {
      .ui-ripple-element {
        background-color: transparent;
      }
    }
  }
}
