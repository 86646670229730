@mixin illustration-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, secondary);

  .ui-illustration {
    color: $primary;

    .primary-fill {
      fill: $primary;
    }

    .primary-stroke {
      stroke: $primary;
    }

    .secondary-fill {
      fill: $secondary;
    }

    .secondary-stroke {
      stroke: $secondary;
    }
  }
}
