// WAIT This file is generated. Please use build-themes.ts to change this file.

$black: #000000;
$g1: #333333;
$g2: #666666;
$g3: #999999;
$g4: #cccccc;
$g5: #f5f5f5;
$g6: #f9f9fb;
$white: #ffffff;
$success: #398538;
$textSuccess: #398538;
$warning: #fa5c00;
$textWarning: #666666;
$error: #e91b06;
$textError: #e91b06;
