@import 'mixins';

// UBUNTU FONT DECLARATION
@include font-face2($font-name, $font-path, '300;400;500;700');

// UBUNTU FONT CSS class
body,
.font-text,
.font-body {
  @include font-text;
}

.font-edito-title,
.font-big-title {
  @include font-edito-title;
}

h1,
.font-page-title,
.font-headline {
  @include font-page-title;
}

h2,
.font-intro,
.font-title {
  @include font-intro;
}

h3,
.font-section-title,
.font-subheading-2 {
  @include font-section-title;
}

h4,
.font-subtitle,
.font-subheading-1 {
  @include font-subtitle;
}

.font-text-bold,
.font-bold {
  @include font-text-bold;
}

.font-text-light,
.font-light {
  @include font-text-light;
}

.font-mention {
  @include font-mention;
}

// ICON FONT DECLARATION
@include font-face2($font-icons-family, $font-icons-path + $font-icons-name);

// ICON FONT CSS class
@import '../../web-assets/common/fonts/89c3-icons';

.bpce-icon {
  @include font-icon;
}

// /! RETROCOMPATIBILITY NOT TO REMOVE /!
.font-icon {
  @extend .bpce-icon;
}
