@mixin header-theme($theme) {
  $prefix: bpce-header;
  $primary: map-get($theme, primary);

  .#{$prefix} {
    &-back,
    &-assist,
    &-close {
      color: $primary;
    }

    &:not(&-with-custom-background) {
      .#{$prefix} {
        &-back {
          color: $primary;
        }

        &-assist,
        &-close {
          color: $primary;
        }
      }
    }
  }
}
