@mixin datatable-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-datatable {
    & &-icon {
      svg {
        &:hover {
          fill: $primary;
        }
      }
    }
  }
}
