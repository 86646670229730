@import '../bpce_modules/common-sass/bpce-sass/variables';
@import '../bpce_modules/ui/theming/themes/cc/_theme-colors.scss';


// Fix height 100vh under Safari iOS

html {
  height: -webkit-fill-available;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

/* Avoid Chrome to see Safari hack */
@supports (-webkit-touch-callout: none) {
  body {
    /* The hack for Safari */
    min-height: -webkit-fill-available;
  }
}

:root {
  --vh: 1vh;
}

body {
  padding: 0;
  margin: 0;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}

// End fix

.bpce-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Avoid browsers rendering the focus ring in some cases.
  outline: 0;

  // Avoid some cases where the browser will still render the native controls (see #9049).
  -webkit-appearance: none;
  -moz-appearance: none;
}

body,
dialog {
  color: #333333 !important;
}

.isInvalid .bpce-input-v2-field {
  border-color: $textError !important
}


.isInvalid .bpce-input-v2-label {
  color: $textError !important
}

.bpce-fix-link-hover:hover {
  cursor: pointer !important;
}

.bpce-infobox-secondary {
  margin-bottom: 0px !important
}

.bpce-modal-header {
  display: block !important;
}

.bpce-footer {
  margin-top: 0 !important;
}

.bpce-modal-body {
  padding-bottom: 16px !important;

  @media (min-width: map-get($bpce-breakpoints, sm)) {
    padding-bottom: 24px !important;
  }
}


.bpce-footer li:not(:last-child):after {
  content: '-';
  margin: 0px 8px
}

//
//.bpce-header-left, .bpce-header-right {
//  @media (min-width: map-get($bpce-breakpoints, sm)) {
//    min-width: 100px!important;
//  }
//}

// Fix iOS modal headers
.bpce-header-left,
.bpce-header-right {
  width: 42px !important;
}

.bpce-header-left {
  button {
    width: 48px;

    @media (min-width: map-get($bpce-breakpoints, sm)) {
      width: auto;
    }
  }
}


.bpce-modal-dialog .bpce-modal-header {
  color: #333333 !important;
  padding: 16px !important;
  font-size: 19px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0 !important;
  text-align: center !important;

  @media (min-width: map-get($bpce-breakpoints, sm)) {
    padding: 24px !important;
  }
}

// Antialising

body {

   /* Support for all WebKit browsers. */
   -webkit-font-smoothing: antialiased;
   /* Support for Firefox. */
   -moz-osx-font-smoothing: grayscale;
}

// Remove letter spacing -0.14px under 960px
body,
.font-text,
.font-body {
  letter-spacing: 0 !important;
}

// Remove letter-spacing ?

// body,
// .font-text,
// .font-body,
// .font-edito-title,
// .font-big-title,
// h1,
// .font-page-title,
// .font-headline,
// h2,
// .font-intro,
// .font-title,
// h3,
// .font-section-title,
// .font-subheading-2,
// h4,
// .font-subtitle,
// .font-subheading-1,
// .font-text-bold,
// .font-bold,
// .font-text-light,
// .font-light,
// .font-mention,
// h4,
// h5,
// h6 {
//   letter-spacing: 0 !important;
// }

// Change for centered content with text and button
.col-lg-6 {
  max-width: 612px;
  width: 100%;
}

// Fix width for titles
app-page-title.col-lg-8 {
  max-width: 816px;
  width: 100%;
}

// Center display block
.block-center {
  margin-left: auto;
  margin-right: auto;
}

// Few changes sm and xxl

@media (min-width: 576px) {

  .container-sm,
  .container {
    // Old: max-width: 540px;
    //max-width: inherit;
    padding-left: 24px;
    padding-right: 24px;
  }
}

// @media (min-width: 768px) {

//   .container-md,
//   .container-sm,
//   .container {
//     max-width: 720px;
//   }
// }

// @media (min-width: 992px) {

//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container {
//     max-width: 960px;
//   }
// }

// @media (min-width: 1200px) {

//   .container-xl,
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container {
//     max-width: 1140px;
//   }
// }

// @media (min-width: 1400px) {

//   .container-xxl,
//   .container-xl,
//   .container-lg,
//   .container-md,
//   .container-sm,
//   .container {
//     max-width: 1248px;
//   }
// }

// Fix button widths
.bpce-button-v2-group button {

  &.bpce-button-v2,
  &.bpce-button-v2-group {
    max-width: 586px !important;
  }
}

// Or this solution for container
.container {
  max-width: 1248px !important;
}

// Fix for image
.row.justify-content-center.image {
  margin-left: -24px;
  margin-right: -24px;
  max-width: 100vw;
  overflow-x: hidden;
}

// Fix modals with Firefox Abdroid

.bpce-modal-animate-container {
  max-width: 100vw;
}
