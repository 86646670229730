@import "@fontsource/ubuntu";
@import "@fontsource/roboto";
@import 'material-icons/iconfont/material-icons.css';

.link {
  text-decoration-line: underline!important;
  color: #333333!important;
  font-size: 13px!important;
  line-height: 18px!important;
}

b, strong {
  font-weight: bold!important;
}

.bpce-toggle-label {
  flex-direction: row-reverse;
}

.bpce-toggle-label-text {
  margin-left: 12px;
}

button {
  white-space: nowrap;
}

abbr {
  text-decoration: none;
}

.link:hover {
  cursor: pointer!important;
}

.bpce-icon.erase:before, .erase.font-icon:before {
  content: "\ea12"!important;
  color: #000000!important;
}
