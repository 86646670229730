@mixin checkbox-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-checkbox {
    &-label:hover &-square {
      border-color: $primary;
    }

    &-icon {
      &-indeterminate {
        line {
          stroke: $primary;
        }
      }
    }

    &-input:checked + &-label {
      .bpce-checkbox-square {
        border-width: 1px;
        border-color: $primary;
        background-color: $primary;
      }
    }
  }
}
