@mixin input-list-v2-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-inputlist {
    &-back {
      color: $primary;
    }

    &-custom-action {
      color: $primary;
    }

    &-panel {
      ul {
        li.bpce-inputlist-selected {
          border-left: 4px solid $primary;
        }
      }
    }
  }
}
