%bpce-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%bpce-button-reset {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
}

%bpce-animate-shimmering {
  overflow: hidden;
  position: relative;
  z-index: 1;
  background-color: #f5f5f5;

  &::after {
    content: '';
    width: 300%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background: #f5f5f5 linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #cccccc 20%, #cccccc 60%, rgba(0, 0, 0, 0) 100%)
      repeat;
    animation: shimmering 1500ms linear infinite;

    @keyframes shimmering {
      0% {
        transform: translate3d(-100%, 0, 0);
      }

      100% {
        transform: translate3d(75%, 0, 0);
      }
    }
  }
}

%bpce-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Avoid browsers rendering the focus ring in some cases.
  outline: 0;

  // Avoid some cases where the browser will still render the native controls (see #9049).
  -webkit-appearance: none;
  -moz-appearance: none;
}

%bpce-visually-hidden-focusable {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
