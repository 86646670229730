@import '~reset-css/sass/reset';
@import '../accordion/accordion-theme';
@import '../action-cell/action-cell-theme';
@import '../alert-banner/alertbanner-theme';
@import '../breadcrumb/breadcrumb-theme';
@import '../button/button-theme';
@import '../button-v2/button-v2-theme';
@import '../button-more/button-more-theme';
@import '../card/card-theme';
@import '../carousel/carousel-theme';
@import '../checkbox/checkbox.component-theme';
@import '../chip/chip-theme';
@import '../core/ripple/ripple';
@import '../datatable/datatable-theme';
@import '../datepicker/datepicker-theme';
@import '../fab/fab-theme';
@import '../footer/footer-theme';
@import '../header/header-theme';
@import '../http-error/http-error-theme';
@import '../icon/icon-theme';
@import '../illustration/illustration-theme';
@import '../infobox/infobox-theme';
@import '../input-list/input-list-theme';
@import '../input-list-v2/input-list-v2-theme';
@import '../input-v2/input-v2-theme';
@import '../input-phone/input-phone-theme';
@import '../input-phone-french/input-phone-french-theme';
@import '../input-big-amount/input-big-amount-theme';
@import '../loader/basic-loader/loader-theme';
@import '../loader/progress-loader/progress-loader-theme';
@import '../menu/menu-theme';
@import '../paginator/paginator-theme';
@import '../paginator-v2/paginator-v2-theme';
@import '../pictogram/pictogram-theme';
@import '../radio/radio-theme';
@import '../rating/rating-theme';
@import '../search/search-theme';
@import '../select/select-theme';
@import '../select-chip/select-chip-theme';
@import '../select-v2/select-v2-theme';
@import '../slider/slider-theme';
@import '../slider-v2/slider-v2-theme';
@import '../tabs/tabs-theme';
@import '../tile/tile-theme';
@import '../typography/fonts';
@import '../unavailable-function/unavailable-function-theme';
@import '../table/table-theme';

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

@function ui-theme($primary, $accent, $smallLogo) {
  @return (primary: $primary, accent: $accent, smallLogo: $smallLogo);
}

@mixin ui-load-theme($theme, $brand) {
  @include _ui-button-ripple-color($theme);
  @include accordion-theme($theme);
  @include action-cell-theme($theme);
  @include alert-banner-theme($theme);
  @include breadcrumb-theme($theme);
  @include button-theme($theme);
  @include button-v2-theme($theme);
  @include button-more-theme($theme);
  @include card-theme($theme, $brand);
  @include carousel-theme($theme);
  @include checkbox-theme($theme);
  @include chip-theme($theme);
  @include datatable-theme($theme);
  @include datepicker-theme($theme);
  @include fab-theme($theme);
  @include footer-theme($theme);
  @include header-theme($theme);
  @include http-error-theme($theme);
  @include icon-theme($theme);
  @include illustration-theme($theme);
  @include infobox-theme($theme);
  @include input-list-theme($theme);
  @include input-list-v2-theme($theme);
  @include input-v2-theme($theme);
  @include input-phone-theme($theme);
  @include input-big-amount-theme($theme);
  @include loader-theme($theme);
  @include menu-theme($theme);
  @include progress-loader-theme($theme);
  @include paginator-theme($theme);
  @include paginator-v2-theme($theme);
  @include pictogram-theme($theme);
  @include radio-theme($theme);
  @include rating-theme($theme);
  @include search-theme($theme);
  @include select-theme($theme);
  @include select-chip-theme($theme);
  @include select-v2-theme($theme);
  @include slider-theme($theme);
  @include slider-v2-theme($theme);
  @include tabs-theme($theme);
  @include tile-theme($theme);
  @include ui-ripple($theme);
  @include unavailable-function-theme($theme);
  @include table-theme($theme);
}
