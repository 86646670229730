@import '../theming/themes/common-colors';

@mixin search-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-search {
    &-erase-btn:hover,
    &-trigger-btn {
      color: $primary;
    }
  }
}
