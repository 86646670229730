@import '../../theming/themes/common-colors';

@mixin progress-loader-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-progress-loader circle:last-child {
    stroke: $primary;
  }

  .bpce-progress-loader-number {
    color: $primary;
  }
}
