@use "sass:list";
@use "sass:math";

@import '../../common-sass/bpce-sass/mixins';
@import 'variables';

// FONTS
@mixin font-face2($typo-name, $typo-path, $typo-weight: 300) {
  @if $typo-path == 'https://fonts.googleapis.com/css2' {
    @import url('https://fonts.googleapis.com/css2?family=#{$typo-name}:wght@#{$typo-weight}&display=swap');
  } @else {
    @font-face {
      font-family: '#{$typo-name}';
      font-style: normal;
      font-weight: $typo-weight;
      src: url('#{$typo-path}.eot'); /* IE9 Compat Modes */
      src: local('#{$typo-name}'), local('#{$typo-name}'), url('#{$typo-path}.eot?#iefix') format('embedded-opentype'),
        url('#{$typo-path}.woff2') format('woff2'), url('#{$typo-path}.woff') format('woff'),
        url('#{$typo-path}.ttf') format('truetype'), url('#{$typo-path}.svg') format('svg'); /* Legacy iOS */
    }
  }
}

@mixin typo-style($font-weight, $font-size, $line-height, $font-family, $letter-spacing) {
  font: $font-weight list.slash($font-size, $line-height) $font-family;
  letter-spacing: $letter-spacing;
}

@function pxtorem($pxValue) {
  $baseFontSize: 16;
  $remSize: #{math.div($pxValue, $baseFontSize)};
  @return #{$remSize}rem;
}

@function remtopx($remValue) {
  $baseFontSize: 16;
  $pxSize: #{$remValue * $baseFontSize};
  @return #{$pxSize}px;
}

@mixin font-edito-title {
  @include typo-style(500, pxtorem(24), pxtorem(32), $font-family, -0.31px);
  @include bpce-media-query-up(md) {
    @include typo-style(300, pxtorem(48), pxtorem(56), $font-family, -0.4px);
  }
}

@mixin font-page-title {
  @include typo-style(500, pxtorem(21), pxtorem(28), $font-family, -0.13px);
  @include bpce-media-query-up(md) {
    @include typo-style(300, pxtorem(32), pxtorem(40), $font-family, -0.1px);
  }
}

@mixin font-intro {
  @include typo-style(300, pxtorem(21), pxtorem(32), $font-family, -0.23px);
  @include bpce-media-query-up(md) {
    @include typo-style(300, pxtorem(24), pxtorem(32), $font-family, -0.1px);
  }
}

@mixin font-section-title {
  @include typo-style(500, pxtorem(19), pxtorem(24), $font-family, -0.1px);
  @include bpce-media-query-up(md) {
    @include typo-style(500, pxtorem(21), pxtorem(32), $font-family, -0.1px);
  }
}

@mixin font-subtitle {
  @include typo-style(400, pxtorem(19), pxtorem(24), $font-family, -0.12px);
  @include bpce-media-query-up(md) {
    @include typo-style(400, pxtorem(19), pxtorem(32), $font-family, -0.12px);
  }
}

@mixin font-text-bold {
  @include typo-style(700, pxtorem(16), pxtorem(22), $font-family, -0.2px);
  @include bpce-media-query-up(md) {
    letter-spacing: 0;
  }
}

@mixin font-text {
  @include typo-style(400, pxtorem(16), pxtorem(22), $font-family, -0.14px);
  @include bpce-media-query-up(md) {
    letter-spacing: 0;
  }
}

@mixin font-text-light {
  @include typo-style(300, pxtorem(16), pxtorem(22), $font-family, -0.14px);
  @include bpce-media-query-up(md) {
    letter-spacing: 0;
  }
}

@mixin font-mention {
  @include typo-style(400, pxtorem(13), pxtorem(18), $font-family, 0);
}

@mixin font-annotation {
  @include typo-style(400, pxtorem(11), pxtorem(12), $font-family, 0);
}

@mixin font-icon {
  display: inline-block;
  font-family: $font-icons-family;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
