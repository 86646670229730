@import '../theming/themes/common-colors';

@mixin footer-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-footer {
    &-legals {
      &-link {
        &:hover {
          color: $primary !important;
        }
      }
    }
  }
}
