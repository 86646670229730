@import '../theming/themes/common-colors';

@mixin chip-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-chip-filter {
    &-input:checked + &-content,
    &-input[checked] + &-content {
      color: $primary;
      border-color: $primary;
      box-shadow: 0 0 0 1px $primary;

      .bpce-chip-filter-icon {
        stroke: $primary;
      }
    }
  }
}
