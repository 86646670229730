@mixin icon-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  // Bi-color icons

  .ui-icon svg {
    // Fill
    .primary-fill {
      fill: $primary;
    }

    .secondary-fill {
      fill: $accent;
    }

    // Stroke
    .primary-stroke {
      stroke: $primary;
    }

    .secondary-stroke {
      stroke: $accent;
    }

    .success-stroke {
      stroke: $success;
    }
  }
}
