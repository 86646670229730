@import './right-menu/right-menu-theme';
@import './left-menu/left-menu-theme';

@mixin desktop-menu-theme($theme) {
  $primary: map-get($theme, primary);
  $logo: map-get($theme, smallLogo);

  @include left-menu-theme($theme);
  @include right-menu-theme($theme);

  .bpce-menubar {
    &-logo {
      background-image: url($logo);
    }

    &-right li:last-child &-item {
      .bpce-right-menu {
        background-color: $primary;
        color: $white;
      }
    }
  }
}
