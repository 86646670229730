@mixin mobile-menu-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-menubar-mobile {
    .bpce-menubar-mobile-item-active {
      .bpce-menubar-mobile-item-label,
      .bpce-menubar-mobile-item-icon {
        color: $primary;
      }
    }
  }
}
