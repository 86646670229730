@import '../theming/themes/common-colors';

@mixin fab-theme($theme) {
  $secondary: map-get($theme, accent);

  .bpce-fab-button {
    background-color: $secondary;

    &:hover {
      background-color: mix(#000000, $secondary, 21%);
    }
  }
}
