@mixin left-menu-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-left-menu {
    &:hover {
      color: $primary;
    }

    &:hover &-icon {
      color: $primary;
    }

    &-active {
      @include font-text-bold;
      color: $primary;

      &::before {
        background-color: $primary;
      }
    }
  }
}
