@mixin tabs-theme($theme) {
  $primary: map-get($theme, primary);

  div #ui-ink-bar {
    background-color: $primary;
  }

  .ui-tabs {
    .ui-tab {
      &:hover,
      &:focus {
        color: $primary;
      }
    }

    .active-tab {
      color: $primary;
    }
  }
}
