@import '../../common-sass/bpce-sass';

@mixin button-v2-theme($theme) {
  $primary: map-get($theme, primary);

  button.bpce-button-v2,
  a.bpce-button-v2 {
    &.bpce-button-v2-primary {
      background-color: $primary;
      border-color: $primary;
      color: $white;
    }

    &.bpce-button-v2-secondary {
      color: $primary;
      border-color: $primary;
      background-color: transparent;
    }

    &.bpce-button-v2-tertiary,
    &.bpce-button-v2-quaternary {
      background-color: transparent;
      border-color: transparent;
    }

    &.bpce-button-v2-tertiary {
      color: $primary;
    }

    &.bpce-button-v2-quaternary {
      color: $g2;
    }

    &.bpce-button-v2-no-mobile-border {
      border-color: transparent;

      @include bpce-media-query-up(md) {
        border-color: $primary;
      }
    }

    // Inverted mode can be used on colored background
    &.bpce-button-v2-inverted {
      color: $white;
      border-color: $white;
    }

    &.bpce-button-v2-no-border {
      border-color: transparent;
    }
  }
}
