@import '../theming/themes/common-colors';

@mixin select-chip-theme($theme) {
  $prefix: bpce-select-chip;
  $primary: map-get($theme, primary);

  .#{$prefix} {
    &-selected:enabled {
      color: $primary;
      border-color: $primary;
      box-shadow: 0 0 0 1px $primary;
    }

    &-selected:enabled ~ &-icon {
      color: $primary;
    }
  }
}
