@mixin paginator-v2-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-paginator-v2 {
    & > button#{&}-button-selected {
      background-color: $primary;
      color: $white;
      border-color: $primary;
    }
  }
}
