@import '../theming/themes/common-colors';
@import '../../common-sass/bpce-sass/variables';

/* Slider dot */
@mixin slider-thumb() {
  background-color: $primary;
  width: $bpce-dim-sm;
  height: $bpce-dim-sm;
  margin-top: -6px;
  border: 0;
  border-radius: $bpce-dim-sm;
  cursor: pointer;
  position: relative;
  z-index: 1;
  pointer-events: auto;
  transform: scale(1);
  transition: background-color 350ms ease, transform 350ms ease;
  appearance: none;
  -webkit-appearance: none;
}

@mixin slider-thumb-disabled() {
  background-color: $g3;
  cursor: default;
}

/* Slider line */
@mixin slider-track() {
  background-color: $g4;
  color: transparent;
  width: 100%;
  height: $bpce-space-xxs;
  cursor: pointer;
  border-color: transparent;
  border-radius: $bpce-space-xxxs;
}

@mixin slider-track-disabled() {
  background-color: rgba($black, 0.08);
  cursor: default;
}

@mixin slider-hover() {
  transform: scale(1.5); // 16px x 1.5 = 24px
}

@mixin slider-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-slider {
    input[type='range'] {
      color: $primary;
      padding: 0;
      margin: $bpce-space-sm 0 0;
      height: $bpce-dim-xxs; // Firefox need it
      width: 100%;
      border-radius: $bpce-space-xxxs;
      -webkit-appearance: none;

      &::-webkit-slider-thumb {
        @include slider-thumb;

        @media (hover: none) {
          /* behaviour for touch browsers */
          width: $bpce-dim-md;
          height: $bpce-dim-md;
          margin-top: -10px;
          transform: scale(1) !important;
        }
      }

      &::-moz-range-thumb {
        @include slider-thumb;
      }

      &::-ms-thumb {
        @include slider-thumb;
        margin-top: 0;
      }

      &::-webkit-slider-runnable-track {
        @include slider-track;
      }

      &::-moz-range-track {
        @include slider-track;
      }

      &::-ms-track {
        @include slider-track;
        color: transparent;
        background-color: transparent;
        border: 0;
      }

      &::-ms-fill-lower {
        @include slider-track;
        background-color: $primary;
      }

      &::-ms-fill-upper {
        @include slider-track;
      }

      &::-ms-tooltip {
        display: none;
      }

      &:hover:not(:disabled) {
        &::-webkit-slider-thumb {
          @include slider-hover;
        }

        &::-moz-range-thumb {
          @include slider-hover;
        }

        &::-ms-thumb {
          @include slider-hover;
        }
      }

      &:focus:not(:disabled) {
        outline: none;

        &::-moz-focus-outer {
          border: 0;
        }

        &::-webkit-slider-thumb {
          @include slider-hover;
        }

        &::-moz-range-thumb {
          @include slider-hover;
        }

        &::-ms-thumb {
          @include slider-hover;
        }
      }

      &:disabled {
        &::-webkit-slider-runnable-track {
          @include slider-track-disabled;
        }

        &::-moz-range-track {
          @include slider-track-disabled;
        }

        &::-ms-fill-upper {
          @include slider-track-disabled;
        }

        &::-webkit-slider-thumb {
          @include slider-thumb-disabled;
        }

        &::-moz-range-thumb {
          @include slider-thumb-disabled;
        }

        &::-ms-thumb {
          @include slider-thumb-disabled;
        }
      }
    }

    &-progress {
      position: absolute;
      bottom: $bpce-dim-xxs;
      height: $bpce-dim-xxs;
      border-radius: $bpce-space-xxxs;
      background: $primary;
      pointer-events: none;
    }

    &-progress-thumb {
      &::before,
      &::after {
        color: $primary;
      }
    }

    input[type='range']:disabled + &-progress {
      background-color: $g3;
      cursor: default;
    }

    input[type='range']:disabled + &-progress-thumb {
      &::before,
      &::after {
        color: $g1;
      }
    }

    .ie & {
      &-multi .bpce-slider-range-container::after {
        @include slider-track;
        background-color: $primary;
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -$bpce-dim-xxs * 0.5;
        width: 100%;
        z-index: 0;
      }
    }
  }
}
