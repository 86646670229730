@mixin http-error-theme($theme) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, accent);

  .primary-stroke {
    stroke: $primary;
  }

  .secondary-stroke {
    stroke: $secondary;
  }

  .error-name {
    color: $primary;
  }
}
