@mixin card-theme($theme, $brand) {
  $primary: map-get($theme, primary);
  $secondary: map-get($theme, accent);

  label.bpce-card {
    // Icon
    .bpce-card-separator .bpce-card-intertitle {
      @if $brand == bp {
        background-color: $secondary;
      } @else {
        background-color: $primary;
      }
    }

    // If selected
    .bpce-card-selected {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;

      @if $brand == bp {
        background: $secondary;
      } @else {
        background: $primary;
      }
    }
  }
}
