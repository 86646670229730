@mixin paginator-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-paginator {
    &-navigation-button-selected {
      background-color: $primary;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        cursor: initial;
      }
    }

    &-page-selected {
      color: $primary;
    }
  }
}
