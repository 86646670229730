@import 'variables';

@mixin bpce-media-query-up($breakpoint) {
  @if $breakpoint == xs {
    @error "Sorry, but passing `xs` to `bpce-media-query-up()` would result in a `min-width: 0px` media query which is the same as not using a media query at all... Maybe you are searching for the `bpce-media-query-on-mobile()` mixin instead?";
  }

  @if map-has-key($bpce-breakpoints, $breakpoint) {
    @media (min-width: map-get($bpce-breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @error "Sorry, but #{$breakpoint} is not a valid breakpoint value. Please use one of: #{map-keys($bpce-breakpoints)} other than xs.";
  }
}

@mixin bpce-media-query-for-mobile {
  @media (max-width: map-get($bpce-breakpoints, 'md')-1px) {
    @content;
  }
}

@mixin bpce-media-query-for-tablet {
  @media (min-width: map-get($bpce-breakpoints, 'md')) and (max-width: map-get($bpce-breakpoints, 'lg')-1px) {
    @content;
  }
}

@mixin bpce-media-query-for-desktop {
  @media (min-width: map-get($bpce-breakpoints, 'lg')) {
    @content;
  }
}

@mixin bpce-media-query($breakpoint) {
  @if $breakpoint == xs {
    // Phone
    @media (max-width: map-get($bpce-breakpoints, sm) - 1px) {
      @content;
    }
  }

  @if $breakpoint == sm {
    // Tablet
    @media (min-width: map-get($bpce-breakpoints, sm)) and (max-width: map-get($bpce-breakpoints, md) - 1px) {
      @content;
    }
  }

  @if $breakpoint == md {
    // Tablet
    @media (min-width: map-get($bpce-breakpoints, md)) and (max-width: map-get($bpce-breakpoints, lg) - 1px) {
      @content;
    }
  }

  @if $breakpoint == lg {
    // Desktop
    @media (min-width: map-get($bpce-breakpoints, lg)) and (max-width: map-get($bpce-breakpoints, xl) - 1px) {
      @content;
    }
  }

  @if $breakpoint == xl {
    // Desktop
    @media (min-width: map-get($bpce-breakpoints, xl)) {
      @content;
    }
  }
}

@mixin multiline-ellipsis($line-count: 3, $ie11-line-height: 1.2em, $ie11-mask-bgcolor: white) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line-count;

  // TODO: ie11 fallback - remove me when ie is not supported anymore
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    position: relative;
    padding-right: 1em;
    line-height: $ie11-line-height;
    max-height: $ie11-line-height * $line-count;

    &::before {
      content: '...';
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      margin-top: 0.2em;
      background: $ie11-mask-bgcolor;
    }
  }
}

@mixin bpce-animate-shimmering {
  overflow: hidden;
  position: relative;
  z-index: 1;
  background-color: #f5f5f5;

  &::after {
    content: '';
    width: 300%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background: #f5f5f5 linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #cccccc 20%, #cccccc 60%, rgba(0, 0, 0, 0) 100%)
      repeat;
    animation: shimmering 1500ms linear infinite;

    @keyframes shimmering {
      0% {
        transform: translate3d(-100%, 0, 0);
      }

      100% {
        transform: translate3d(75%, 0, 0);
      }
    }
  }
}
