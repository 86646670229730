/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@keyframes shimmering {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(75%, 0, 0);
  }
}

@keyframes shimmering {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(75%, 0, 0);
  }
}

@keyframes shimmering {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(75%, 0, 0);
  }
}

/* Slider dot */
/* Slider line */
/* Slider dot */
/* Slider line */
body,
.font-text,
.font-body {
  font: 400 1rem / 1.375rem "Ubuntu", Arial, sans-serif;
  letter-spacing: -0.14px;
}
@media (min-width: 960px) {
  body,
.font-text,
.font-body {
    letter-spacing: 0;
  }
}

.font-edito-title,
.font-big-title {
  font: 500 1.5rem / 2rem "Ubuntu", Arial, sans-serif;
  letter-spacing: -0.31px;
}
@media (min-width: 960px) {
  .font-edito-title,
.font-big-title {
    font: 300 3rem / 3.5rem "Ubuntu", Arial, sans-serif;
    letter-spacing: -0.4px;
  }
}

h1,
.font-page-title,
.font-headline {
  font: 500 1.3125rem / 1.75rem "Ubuntu", Arial, sans-serif;
  letter-spacing: -0.13px;
}
@media (min-width: 960px) {
  h1,
.font-page-title,
.font-headline {
    font: 300 2rem / 2.5rem "Ubuntu", Arial, sans-serif;
    letter-spacing: -0.1px;
  }
}

h2,
.font-intro,
.font-title {
  font: 300 1.3125rem / 2rem "Ubuntu", Arial, sans-serif;
  letter-spacing: -0.23px;
}
@media (min-width: 960px) {
  h2,
.font-intro,
.font-title {
    font: 300 1.5rem / 2rem "Ubuntu", Arial, sans-serif;
    letter-spacing: -0.1px;
  }
}

h3,
.font-section-title,
.font-subheading-2 {
  font: 500 1.1875rem / 1.5rem "Ubuntu", Arial, sans-serif;
  letter-spacing: -0.1px;
}
@media (min-width: 960px) {
  h3,
.font-section-title,
.font-subheading-2 {
    font: 500 1.3125rem / 2rem "Ubuntu", Arial, sans-serif;
    letter-spacing: -0.1px;
  }
}

h4,
.font-subtitle,
.font-subheading-1 {
  font: 400 1.1875rem / 1.5rem "Ubuntu", Arial, sans-serif;
  letter-spacing: -0.12px;
}
@media (min-width: 960px) {
  h4,
.font-subtitle,
.font-subheading-1 {
    font: 400 1.1875rem / 2rem "Ubuntu", Arial, sans-serif;
    letter-spacing: -0.12px;
  }
}

.font-text-bold,
.font-bold {
  font: 700 1rem / 1.375rem "Ubuntu", Arial, sans-serif;
  letter-spacing: -0.2px;
}
@media (min-width: 960px) {
  .font-text-bold,
.font-bold {
    letter-spacing: 0;
  }
}

.font-text-light,
.font-light {
  font: 300 1rem / 1.375rem "Ubuntu", Arial, sans-serif;
  letter-spacing: -0.14px;
}
@media (min-width: 960px) {
  .font-text-light,
.font-light {
    letter-spacing: 0;
  }
}

.font-mention {
  font: 400 0.8125rem / 1.125rem "Ubuntu", Arial, sans-serif;
  letter-spacing: 0;
}

@font-face {
  font-family: "89c3";
  font-style: normal;
  font-weight: 300;
  src: url("~@bpce/web-assets/common/fonts/89c3-icons.eot");
  /* IE9 Compat Modes */
  src: local("89c3"), local("89c3"), url("~@bpce/web-assets/common/fonts/89c3-icons.eot?#iefix") format("embedded-opentype"), url("~@bpce/web-assets/common/fonts/89c3-icons.woff2") format("woff2"), url("~@bpce/web-assets/common/fonts/89c3-icons.woff") format("woff"), url("~@bpce/web-assets/common/fonts/89c3-icons.ttf") format("truetype"), url("~@bpce/web-assets/common/fonts/89c3-icons.svg") format("svg");
  /* Legacy iOS */
}
.bpce-icon.add:before, .add.font-icon:before {
  content: "\ea01";
}
.bpce-icon.browser:before, .browser.font-icon:before {
  content: "\ea03";
}
.bpce-icon.calendar-ok:before, .calendar-ok.font-icon:before {
  content: "\ea04";
}
.bpce-icon.calendar:before, .calendar.font-icon:before {
  content: "\ea05";
}
.bpce-icon.call:before, .call.font-icon:before {
  content: "\ea06";
}
.bpce-icon.card:before, .card.font-icon:before {
  content: "\ea07";
}
.bpce-icon.check-circle:before, .check-circle.font-icon:before {
  content: "\ea08";
}
.bpce-icon.check:before, .check.font-icon:before {
  content: "\ea09";
}
.bpce-icon.checkbox-off:before, .checkbox-off.font-icon:before {
  content: "\ea0a";
}
.bpce-icon.checkbox-on:before, .checkbox-on.font-icon:before {
  content: "\ea0b";
}
.bpce-icon.close:before, .close.font-icon:before {
  content: "\ea0c";
}
.bpce-icon.cloud:before, .cloud.font-icon:before {
  content: "\ea0d";
}
.bpce-icon.download-cloud:before, .download-cloud.font-icon:before {
  content: "\ea0e";
}
.bpce-icon.download:before, .download.font-icon:before {
  content: "\ea0f";
}
.bpce-icon.edit:before, .edit.font-icon:before {
  content: "\ea10";
}
.bpce-icon.email:before, .email.font-icon:before {
  content: "\ea11";
}
.bpce-icon.erase:before, .erase.font-icon:before {
  content: "\ea12";
}
.bpce-icon.favoris:before, .favoris.font-icon:before {
  content: "\ea13";
}
.bpce-icon.folder:before, .folder.font-icon:before {
  content: "\ea14";
}
.bpce-icon.geolocation-pin:before, .geolocation-pin.font-icon:before {
  content: "\ea15";
}
.bpce-icon.information:before, .information.font-icon:before {
  content: "\ea17";
}
.bpce-icon.help:before, .help.font-icon:before {
  content: "\ea16";
}
.bpce-icon.profil:before, .profil.font-icon:before {
  content: "\ea1a";
}
.bpce-icon.phone:before, .phone.font-icon:before {
  content: "\ea19";
}
.bpce-icon.refresh:before, .refresh.font-icon:before {
  content: "\ea1b";
}
.bpce-icon.remove:before, .remove.font-icon:before {
  content: "\ea1c";
}
.bpce-icon.share:before, .share.font-icon:before {
  content: "\ea1e";
}
.bpce-icon.secure:before, .secure.font-icon:before {
  content: "\ea1d";
}
.bpce-icon.trash:before, .trash.font-icon:before {
  content: "\ea1f";
}
.bpce-icon.arrow-up:before, .arrow-up.font-icon:before {
  content: "\ea22";
}
.bpce-icon.warning:before, .warning.font-icon:before {
  content: "\ea20";
}
.bpce-icon.arrow-down:before, .arrow-down.font-icon:before {
  content: "\ea23";
}
.bpce-icon.target:before, .target.font-icon:before {
  content: "\ea24";
}
.bpce-icon.camera:before, .camera.font-icon:before {
  content: "\ea25";
}
.bpce-icon.log-out:before, .log-out.font-icon:before {
  content: "\ea27";
}
.bpce-icon.find:before, .find.font-icon:before {
  content: "\ea26";
}
.bpce-icon.page:before, .page.font-icon:before {
  content: "\ea28";
}
.bpce-icon.file-doc:before, .file-doc.font-icon:before {
  content: "\ea2a";
}
.bpce-icon.euro:before, .euro.font-icon:before {
  content: "\ea29";
}
.bpce-icon.file-pdf:before, .file-pdf.font-icon:before {
  content: "\ea2b";
}
.bpce-icon.file-ppt:before, .file-ppt.font-icon:before {
  content: "\ea2c";
}
.bpce-icon.chat:before, .chat.font-icon:before {
  content: "\ea30";
}
.bpce-icon.file-xls:before, .file-xls.font-icon:before {
  content: "\ea2d";
}
.bpce-icon.eye:before, .eye.font-icon:before {
  content: "\ea31";
}
.bpce-icon.eye-close:before, .eye-close.font-icon:before {
  content: "\ea32";
}
.bpce-icon.support-help:before, .support-help.font-icon:before {
  content: "\ea34";
}
.bpce-icon.call-back:before, .call-back.font-icon:before {
  content: "\ea33";
}
.bpce-icon.more-details:before, .more-details.font-icon:before {
  content: "\ea35";
}
.bpce-icon.chevron-down:before, .chevron-down.font-icon:before {
  content: "\ea36";
}
.bpce-icon.chevron-up:before, .chevron-up.font-icon:before {
  content: "\ea37";
}
.bpce-icon.chevron-left:before, .chevron-left.font-icon:before {
  content: "\ea38";
}
.bpce-icon.chevron-right:before, .chevron-right.font-icon:before {
  content: "\ea39";
}
.bpce-icon.alert:before, .alert.font-icon:before {
  content: "\ea3a";
}
.bpce-icon.app-facebook:before, .app-facebook.font-icon:before {
  content: "\ea3b";
}
.bpce-icon.app-twitter:before, .app-twitter.font-icon:before {
  content: "\ea3d";
}
.bpce-icon.app-skype:before, .app-skype.font-icon:before {
  content: "\ea3c";
}
.bpce-icon.app-video:before, .app-video.font-icon:before {
  content: "\ea3e";
}
.bpce-icon.money-out:before, .money-out.font-icon:before {
  content: "\ea41";
}
.bpce-icon.money-in:before, .money-in.font-icon:before {
  content: "\ea42";
}
.bpce-icon.delete:before, .delete.font-icon:before {
  content: "\ea43";
}
.bpce-icon.upload-cloud:before, .upload-cloud.font-icon:before {
  content: "\ea40";
}
.bpce-icon.grid:before, .grid.font-icon:before {
  content: "\ea45";
}
.bpce-icon.list:before, .list.font-icon:before {
  content: "\ea44";
}
.bpce-icon.reach-the-end:before, .reach-the-end.font-icon:before {
  content: "\ea47";
}
.bpce-icon.reach-the-start:before, .reach-the-start.font-icon:before {
  content: "\ea46";
}
.bpce-icon.molecule:before, .molecule.font-icon:before {
  content: "\ea4a";
}
.bpce-icon.plus:before, .plus.font-icon:before {
  content: "\ea4b";
}
.bpce-icon.filter:before, .filter.font-icon:before {
  content: "\ea48";
}
.bpce-icon.bulle:before, .bulle.font-icon:before {
  content: "\ea49";
}
.bpce-icon.moins:before, .moins.font-icon:before {
  content: "\ea4c";
}
.bpce-icon.reduce:before, .reduce.font-icon:before {
  content: "\ea4e";
}
.bpce-icon.exchange:before, .exchange.font-icon:before {
  content: "\ea50";
}
.bpce-icon.expand:before, .expand.font-icon:before {
  content: "\ea4d";
}
.bpce-icon.call-out:before, .call-out.font-icon:before {
  content: "\ea52";
}
.bpce-icon.copy:before, .copy.font-icon:before {
  content: "\ea51";
}
.bpce-icon.arrow-downleft:before, .arrow-downleft.font-icon:before {
  content: "\ea53";
}
.bpce-icon.arrow-downright:before, .arrow-downright.font-icon:before {
  content: "\ea54";
}
.bpce-icon.arrow-upleft:before, .arrow-upleft.font-icon:before {
  content: "\ea57";
}
.bpce-icon.arrow-right:before, .arrow-right.font-icon:before {
  content: "\ea56";
}
.bpce-icon.arrow-left:before, .arrow-left.font-icon:before {
  content: "\ea55";
}
.bpce-icon.arrow-upright:before, .arrow-upright.font-icon:before {
  content: "\ea58";
}
.bpce-icon.building:before, .building.font-icon:before {
  content: "\ea59";
}
.bpce-icon.cart:before, .cart.font-icon:before {
  content: "\ea5b";
}
.bpce-icon.calculator:before, .calculator.font-icon:before {
  content: "\ea5a";
}
.bpce-icon.charts-bar:before, .charts-bar.font-icon:before {
  content: "\ea5c";
}
.bpce-icon.charts-linedown:before, .charts-linedown.font-icon:before {
  content: "\ea5d";
}
.bpce-icon.historic:before, .historic.font-icon:before {
  content: "\ea60";
}
.bpce-icon.charts-lineup:before, .charts-lineup.font-icon:before {
  content: "\ea5e";
}
.bpce-icon.charts-pie:before, .charts-pie.font-icon:before {
  content: "\ea5f";
}
.bpce-icon.home:before, .home.font-icon:before {
  content: "\ea61";
}
.bpce-icon.menu:before, .menu.font-icon:before {
  content: "\ea62";
}
.bpce-icon.print:before, .print.font-icon:before {
  content: "\ea64";
}
.bpce-icon.more:before, .more.font-icon:before {
  content: "\ea63";
}
.bpce-icon.cogwheel:before, .cogwheel.font-icon:before {
  content: "\ea65";
}
.bpce-icon.media-play:before, .media-play.font-icon:before {
  content: "\ea66";
}
.bpce-icon.media-duration:before, .media-duration.font-icon:before {
  content: "\ea68";
}
.bpce-icon.repeat:before, .repeat.font-icon:before {
  content: "\ea69";
}
.bpce-icon.media-pause:before, .media-pause.font-icon:before {
  content: "\ea67";
}
.bpce-icon.sun:before, .sun.font-icon:before {
  content: "\ea6a";
}
.bpce-icon.moon:before, .moon.font-icon:before {
  content: "\ea6b";
}
.bpce-icon.thumbs-up:before, .thumbs-up.font-icon:before {
  content: "\ea6d";
}
.bpce-icon.link:before, .link.font-icon:before {
  content: "\ea6c";
}
.bpce-icon.thumbs-down:before, .thumbs-down.font-icon:before {
  content: "\ea6e";
}
.bpce-icon.app-linkedin:before, .app-linkedin.font-icon:before {
  content: "\ea6f";
}
.bpce-icon.app-instagram:before, .app-instagram.font-icon:before {
  content: "\ea71";
}
.bpce-icon.app-pinterest:before, .app-pinterest.font-icon:before {
  content: "\ea70";
}
.bpce-icon.app-slack:before, .app-slack.font-icon:before {
  content: "\ea74";
}
.bpce-icon.alert-active:before, .alert-active.font-icon:before {
  content: "\ea75";
}
.bpce-icon.app-snapchat:before, .app-snapchat.font-icon:before {
  content: "\ea73";
}
.bpce-icon.server:before, .server.font-icon:before {
  content: "\ea76";
}
.bpce-icon.box:before, .box.font-icon:before {
  content: "\ea77";
}
.bpce-icon.app-tiktok:before, .app-tiktok.font-icon:before {
  content: "\ea72";
}
.bpce-icon.image:before, .image.font-icon:before {
  content: "\ea79";
}
.bpce-icon.shield:before, .shield.font-icon:before {
  content: "\ea7a";
}
.bpce-icon.layers:before, .layers.font-icon:before {
  content: "\ea78";
}
.bpce-icon.umbrella:before, .umbrella.font-icon:before {
  content: "\ea7b";
}
.bpce-icon.doc-alt:before, .doc-alt.font-icon:before {
  content: "\ea7c";
}
.bpce-icon.upload:before, .upload.font-icon:before {
  content: "\ea7d";
}
.bpce-icon.favoris-active:before, .favoris-active.font-icon:before {
  content: "\ea7e";
}
.bpce-icon.heart-active:before, .heart-active.font-icon:before {
  content: "\ea7f";
}
.bpce-icon.checkbox-indeterminate:before, .checkbox-indeterminate.font-icon:before {
  content: "\ea81";
}
.bpce-icon.subscribe:before, .subscribe.font-icon:before {
  content: "\ea80";
}
.bpce-icon.envelope:before, .envelope.font-icon:before {
  content: "\ea82";
}
.bpce-icon.connections:before, .connections.font-icon:before {
  content: "\ea83";
}
.bpce-icon.face-frowning:before, .face-frowning.font-icon:before {
  content: "\ea87";
}
.bpce-icon.face-smiling:before, .face-smiling.font-icon:before {
  content: "\ea85";
}
.bpce-icon.accessibility:before, .accessibility.font-icon:before {
  content: "\ea84";
}
.bpce-icon.face-neutral:before, .face-neutral.font-icon:before {
  content: "\ea86";
}
.bpce-icon.face-smiling-active:before, .face-smiling-active.font-icon:before {
  content: "\ea8b";
}
.bpce-icon.face-neutral-active:before, .face-neutral-active.font-icon:before {
  content: "\ea8c";
}
.bpce-icon.face-frowning-active:before, .face-frowning-active.font-icon:before {
  content: "\ea8d";
}
.bpce-icon.unlink:before, .unlink.font-icon:before {
  content: "\ea8f";
}
.bpce-icon.tag:before, .tag.font-icon:before {
  content: "\ea90";
}
.bpce-icon.list2:before, .list2.font-icon:before {
  content: "\ea92";
}
.bpce-icon.external-link:before, .external-link.font-icon:before {
  content: "\ea93";
}
.bpce-icon.mic:before, .mic.font-icon:before {
  content: "\ea91";
}
.bpce-icon.drag:before, .drag.font-icon:before {
  content: "\ea94";
}
.bpce-icon.arrow-up-arrow-down:before, .arrow-up-arrow-down.font-icon:before {
  content: "\ea96";
}
.bpce-icon.percent:before, .percent.font-icon:before {
  content: "\ea95";
}

.bpce-icon, .font-icon {
  display: inline-block;
  font-family: "89c3";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for IE. */
  font-feature-settings: "liga";
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

button.ui-button.primary .ui-ripple-element,
a.ui-button.primary .ui-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.bpce-accordion-title-arrow {
  color: #c8102e;
}

ui-cell .bpce-cell-content .bpce-cell-with-primary, ui-cell .bpce-cell-icon {
  color: #c8102e;
}

.bpce-alertbanner-content a:hover {
  color: #c8102e;
}

.bpce-breadcrumbs-item-link {
  color: #c8102e;
}

button.ui-button.primary,
a.ui-button.primary {
  background-color: #c8102e;
  color: #ffffff;
}
button.ui-button.outline,
a.ui-button.outline {
  background-color: #ffffff;
  border: thin solid currentColor;
  color: #c8102e;
}
button.ui-button.clear,
a.ui-button.clear {
  background-color: #ffffff;
  color: #c8102e;
}
button.ui-button.tertiary,
a.ui-button.tertiary {
  color: #c8102e;
}
button.ui-button.quaternary,
a.ui-button.quaternary {
  color: #666666;
}

button.bpce-button-v2.bpce-button-v2-primary,
a.bpce-button-v2.bpce-button-v2-primary {
  background-color: #c8102e;
  border-color: #c8102e;
  color: #ffffff;
}
button.bpce-button-v2.bpce-button-v2-secondary,
a.bpce-button-v2.bpce-button-v2-secondary {
  color: #c8102e;
  border-color: #c8102e;
  background-color: transparent;
}
button.bpce-button-v2.bpce-button-v2-tertiary, button.bpce-button-v2.bpce-button-v2-quaternary,
a.bpce-button-v2.bpce-button-v2-tertiary,
a.bpce-button-v2.bpce-button-v2-quaternary {
  background-color: transparent;
  border-color: transparent;
}
button.bpce-button-v2.bpce-button-v2-tertiary,
a.bpce-button-v2.bpce-button-v2-tertiary {
  color: #c8102e;
}
button.bpce-button-v2.bpce-button-v2-quaternary,
a.bpce-button-v2.bpce-button-v2-quaternary {
  color: #666666;
}
button.bpce-button-v2.bpce-button-v2-no-mobile-border,
a.bpce-button-v2.bpce-button-v2-no-mobile-border {
  border-color: transparent;
}
@media (min-width: 960px) {
  button.bpce-button-v2.bpce-button-v2-no-mobile-border,
a.bpce-button-v2.bpce-button-v2-no-mobile-border {
    border-color: #c8102e;
  }
}
button.bpce-button-v2.bpce-button-v2-inverted,
a.bpce-button-v2.bpce-button-v2-inverted {
  color: #ffffff;
  border-color: #ffffff;
}
button.bpce-button-v2.bpce-button-v2-no-border,
a.bpce-button-v2.bpce-button-v2-no-border {
  border-color: transparent;
}

button.button-more-theme,
a.button-more-theme > span {
  color: #c8102e;
  background-color: transparent;
}

label.bpce-card .bpce-card-separator .bpce-card-intertitle {
  background-color: #c8102e;
}
label.bpce-card .bpce-card-selected {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: #c8102e;
}

.bpce-carrousel .bpce-carrousel-dot.bpce-carrousel-dot-active::before {
  background-color: #c8102e;
}

.bpce-checkbox-label:hover .bpce-checkbox-square {
  border-color: #c8102e;
}
.bpce-checkbox-icon-indeterminate line {
  stroke: #c8102e;
}
.bpce-checkbox-input:checked + .bpce-checkbox-label .bpce-checkbox-square {
  border-width: 1px;
  border-color: #c8102e;
  background-color: #c8102e;
}

.bpce-chip-filter-input:checked + .bpce-chip-filter-content, .bpce-chip-filter-input[checked] + .bpce-chip-filter-content {
  color: #c8102e;
  border-color: #c8102e;
  box-shadow: 0 0 0 1px #c8102e;
}
.bpce-chip-filter-input:checked + .bpce-chip-filter-content .bpce-chip-filter-icon, .bpce-chip-filter-input[checked] + .bpce-chip-filter-content .bpce-chip-filter-icon {
  stroke: #c8102e;
}

.bpce-datatable .bpce-datatable-icon svg:hover {
  fill: #c8102e;
}

.ui-calendar-view .ui-calendar-cell .ui-calendar-cell-button {
  border-radius: 20px;
  color: #333333;
}
.ui-calendar-view .ui-calendar-cell .ui-calendar-cell-button:focus, .ui-calendar-view .ui-calendar-cell .ui-calendar-cell-button:hover {
  background-color: #f5f5f5;
}
.ui-calendar-view .ui-calendar-cell .ui-calendar-cell-button.ui-calendar-cell-selected {
  color: #ffffff;
  background-color: #c8102e !important;
}
.ui-calendar-view .ui-calendar-cell .ui-calendar-cell-button.ui-calendar-cell-disabled {
  color: #999999;
}
.ui-calendar-view .ui-calendar-cell .ui-calendar-cell-button.ui-calendar-cell-restricted {
  border-radius: 0;
  color: #999999;
  background-color: #f5f5f5;
  text-decoration: line-through;
}

.bpce-fab-button {
  background-color: #ec6919;
}
.bpce-fab-button:hover {
  background-color: #ba5314;
}

.bpce-footer-legals-link:hover {
  color: #c8102e !important;
}

.bpce-header-back, .bpce-header-assist, .bpce-header-close {
  color: #c8102e;
}
.bpce-header:not(.bpce-header-with-custom-background) .bpce-header-back {
  color: #c8102e;
}
.bpce-header:not(.bpce-header-with-custom-background) .bpce-header-assist, .bpce-header:not(.bpce-header-with-custom-background) .bpce-header-close {
  color: #c8102e;
}

.primary-stroke {
  stroke: #c8102e;
}

.secondary-stroke {
  stroke: #ec6919;
}

.error-name {
  color: #c8102e;
}

.ui-icon svg .primary-fill {
  fill: #c8102e;
}
.ui-icon svg .secondary-fill {
  fill: #ec6919;
}
.ui-icon svg .primary-stroke {
  stroke: #c8102e;
}
.ui-icon svg .secondary-stroke {
  stroke: #ec6919;
}
.ui-icon svg .success-stroke {
  stroke: #398538;
}

.ui-illustration {
  color: #c8102e;
}
.ui-illustration .primary-fill {
  fill: #c8102e;
}
.ui-illustration .primary-stroke {
  stroke: #c8102e;
}
.bpce-infobox {
  border: 1px solid #ec6919;
}
.bpce-infobox-content a:hover {
  color: #c8102e;
}
.bpce-infobox-icon {
  color: #ec6919;
}
.bpce-infobox-action {
  color: #c8102e;
}

.bpce-inputlist-back {
  color: #c8102e;
}

.bpce-inputlist-back {
  color: #c8102e;
}
.bpce-inputlist-custom-action {
  color: #c8102e;
}
.bpce-inputlist-panel ul li.bpce-inputlist-selected {
  border-left: 4px solid #c8102e;
}

.bpce-input-v2-icon:hover .ui-icon, .bpce-input-v2-icon:focus .ui-icon {
  color: #c8102e;
}

.bpce-input-phone-prefix.bpce-inputlist-opened .bpce-inputlist-header .bpce-inputlist-icon-down {
  color: #c8102e;
}

.bpce-input-big-amount::placeholder, .bpce-input-big-amount:placeholder-shown {
  color: #c8102e;
}
.bpce-input-big-amount:placeholder-shown {
  opacity: 0.4;
}

.bpce-loader svg circle {
  stroke: #c8102e;
}

.bpce-loader.bpce-loader-logo {
  background-image: url("~@bpce/web-assets/brands/cc/img/logo-small.svg");
}

.bpce-left-menu:hover {
  color: #c8102e;
}
.bpce-left-menu:hover .bpce-left-menu-icon {
  color: #c8102e;
}
.bpce-left-menu-active {
  font: 700 1rem / 1.375rem "Ubuntu", Arial, sans-serif;
  letter-spacing: -0.2px;
  color: #c8102e;
}
@media (min-width: 960px) {
  .bpce-left-menu-active {
    letter-spacing: 0;
  }
}
.bpce-left-menu-active::before {
  background-color: #c8102e;
}

.bpce-right-menu:hover {
  color: #c8102e;
}
.bpce-right-menu:hover .bpce-right-menu-icon {
  fill: #c8102e;
}

.bpce-menubar-logo {
  background-image: url("~@bpce/web-assets/brands/cc/img/logo-small.svg");
}
.bpce-menubar-right li:last-child .bpce-menubar-item .bpce-right-menu {
  background-color: #c8102e;
  color: #ffffff;
}

.bpce-menubar-mobile .bpce-menubar-mobile-item-active .bpce-menubar-mobile-item-label,
.bpce-menubar-mobile .bpce-menubar-mobile-item-active .bpce-menubar-mobile-item-icon {
  color: #c8102e;
}

.bpce-progress-loader circle:last-child {
  stroke: #c8102e;
}

.bpce-progress-loader-number {
  color: #c8102e;
}

.bpce-paginator-navigation-button-selected {
  background-color: #c8102e;
  color: #ffffff;
  cursor: pointer;
}
.bpce-paginator-navigation-button-selected:hover {
  cursor: initial;
}
.bpce-paginator-page-selected {
  color: #c8102e;
}

.bpce-paginator-v2 > button.bpce-paginator-v2-button-selected {
  background-color: #c8102e;
  color: #ffffff;
  border-color: #c8102e;
}

.ui-pictogram svg .primary-fill {
  fill: #c8102e;
}
.ui-pictogram svg .secondary-fill {
  fill: #ec6919;
}
.ui-pictogram svg .primary-stroke {
  stroke: #c8102e;
}
.ui-pictogram svg .secondary-stroke {
  stroke: #ec6919;
}

.ui-pictogram.ui-pictogram-reverse svg .primary-fill,
.ui-pictogram.ui-pictogram-reverse svg .secondary-fill {
  fill: #ffffff;
}
.ui-pictogram.ui-pictogram-reverse svg .primary-stroke,
.ui-pictogram.ui-pictogram-reverse svg .secondary-stroke {
  stroke: #ffffff;
}

.bpce-radio-label:hover:before {
  border-color: #c8102e;
}
.bpce-radio-input:checked + .bpce-radio-label:before {
  border-color: #c8102e;
}
.bpce-radio-input:checked + .bpce-radio-label:after {
  background-color: #c8102e;
}

.bpce-rating-radio {
  color: #c8102e;
}

.bpce-search-erase-btn:hover, .bpce-search-trigger-btn {
  color: #c8102e;
}

.bpce-rwd-header .icon-back {
  color: #c8102e;
}

li[uiSelectOption][aria-selected=true] {
  color: #c8102e;
}

.bpce-select-chip-selected:enabled {
  color: #c8102e;
  border-color: #c8102e;
  box-shadow: 0 0 0 1px #c8102e;
}
.bpce-select-chip-selected:enabled ~ .bpce-select-chip-icon {
  color: #c8102e;
}

.bpce-select-v2:hover ui-icon, .bpce-select-v2:focus ui-icon {
  color: #c8102e;
}
.bpce-select-v2.bpce-select-v2-disabled:hover ui-icon {
  color: #999999;
}

.bpce-slider input[type=range] {
  color: #c8102e;
  padding: 0;
  margin: 16px 0 0;
  height: 4px;
  width: 100%;
  border-radius: 2px;
  -webkit-appearance: none;
}
.bpce-slider input[type=range]::-webkit-slider-thumb {
  background-color: #c8102e;
  width: 16px;
  height: 16px;
  margin-top: -6px;
  border: 0;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  pointer-events: auto;
  transform: scale(1);
  transition: background-color 350ms ease, transform 350ms ease;
  appearance: none;
  -webkit-appearance: none;
}
@media (hover: none) {
  .bpce-slider input[type=range]::-webkit-slider-thumb {
    /* behaviour for touch browsers */
    width: 24px;
    height: 24px;
    margin-top: -10px;
    transform: scale(1) !important;
  }
}
.bpce-slider input[type=range]::-moz-range-thumb {
  background-color: #c8102e;
  width: 16px;
  height: 16px;
  margin-top: -6px;
  border: 0;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  pointer-events: auto;
  transform: scale(1);
  transition: background-color 350ms ease, transform 350ms ease;
  appearance: none;
  -webkit-appearance: none;
}
.bpce-slider input[type=range]::-ms-thumb {
  background-color: #c8102e;
  width: 16px;
  height: 16px;
  margin-top: -6px;
  border: 0;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  pointer-events: auto;
  transform: scale(1);
  transition: background-color 350ms ease, transform 350ms ease;
  appearance: none;
  -webkit-appearance: none;
  margin-top: 0;
}
.bpce-slider input[type=range]::-webkit-slider-runnable-track {
  background-color: #cccccc;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 2px;
}
.bpce-slider input[type=range]::-moz-range-track {
  background-color: #cccccc;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 2px;
}
.bpce-slider input[type=range]::-ms-track {
  background-color: #cccccc;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 2px;
  color: transparent;
  background-color: transparent;
  border: 0;
}
.bpce-slider input[type=range]::-ms-fill-lower {
  background-color: #cccccc;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 2px;
  background-color: #c8102e;
}
.bpce-slider input[type=range]::-ms-fill-upper {
  background-color: #cccccc;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 2px;
}
.bpce-slider input[type=range]::-ms-tooltip {
  display: none;
}
.bpce-slider input[type=range]:hover:not(:disabled)::-webkit-slider-thumb {
  transform: scale(1.5);
}
.bpce-slider input[type=range]:hover:not(:disabled)::-moz-range-thumb {
  transform: scale(1.5);
}
.bpce-slider input[type=range]:hover:not(:disabled)::-ms-thumb {
  transform: scale(1.5);
}
.bpce-slider input[type=range]:focus:not(:disabled) {
  outline: none;
}
.bpce-slider input[type=range]:focus:not(:disabled)::-moz-focus-outer {
  border: 0;
}
.bpce-slider input[type=range]:focus:not(:disabled)::-webkit-slider-thumb {
  transform: scale(1.5);
}
.bpce-slider input[type=range]:focus:not(:disabled)::-moz-range-thumb {
  transform: scale(1.5);
}
.bpce-slider input[type=range]:focus:not(:disabled)::-ms-thumb {
  transform: scale(1.5);
}
.bpce-slider input[type=range]:disabled::-webkit-slider-runnable-track {
  background-color: rgba(0, 0, 0, 0.08);
  cursor: default;
}
.bpce-slider input[type=range]:disabled::-moz-range-track {
  background-color: rgba(0, 0, 0, 0.08);
  cursor: default;
}
.bpce-slider input[type=range]:disabled::-ms-fill-upper {
  background-color: rgba(0, 0, 0, 0.08);
  cursor: default;
}
.bpce-slider input[type=range]:disabled::-webkit-slider-thumb {
  background-color: #999999;
  cursor: default;
}
.bpce-slider input[type=range]:disabled::-moz-range-thumb {
  background-color: #999999;
  cursor: default;
}
.bpce-slider input[type=range]:disabled::-ms-thumb {
  background-color: #999999;
  cursor: default;
}
.bpce-slider-progress {
  position: absolute;
  bottom: 4px;
  height: 4px;
  border-radius: 2px;
  background: #c8102e;
  pointer-events: none;
}
.bpce-slider-progress-thumb::before, .bpce-slider-progress-thumb::after {
  color: #c8102e;
}
input[type=range]:disabled + .bpce-slider-progress {
  background-color: #999999;
  cursor: default;
}
input[type=range]:disabled + .bpce-slider-progress-thumb::before, input[type=range]:disabled + .bpce-slider-progress-thumb::after {
  color: #333333;
}
.ie .bpce-slider-multi .bpce-slider-range-container::after {
  background-color: #cccccc;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 2px;
  background-color: #c8102e;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -2px;
  width: 100%;
  z-index: 0;
}

.bpce-slider input[type=range] {
  color: #c8102e;
  padding: 0;
  margin: 16px 0 0;
  height: 4px;
  width: 100%;
  border-radius: 2px;
  -webkit-appearance: none;
}
.bpce-slider input[type=range]::-webkit-slider-thumb {
  background-color: #c8102e;
  width: 16px;
  height: 16px;
  margin-top: -6px;
  border: 0;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  pointer-events: auto;
  transform: scale(1);
  transition: background-color 350ms ease, transform 350ms ease;
  appearance: none;
  -webkit-appearance: none;
}
@media (hover: none) {
  .bpce-slider input[type=range]::-webkit-slider-thumb {
    /* behaviour for touch browsers */
    width: 24px;
    height: 24px;
    margin-top: -10px;
    transform: scale(1) !important;
  }
}
.bpce-slider input[type=range]::-moz-range-thumb {
  background-color: #c8102e;
  width: 16px;
  height: 16px;
  margin-top: -6px;
  border: 0;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  pointer-events: auto;
  transform: scale(1);
  transition: background-color 350ms ease, transform 350ms ease;
  appearance: none;
  -webkit-appearance: none;
}
.bpce-slider input[type=range]::-ms-thumb {
  background-color: #c8102e;
  width: 16px;
  height: 16px;
  margin-top: -6px;
  border: 0;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  pointer-events: auto;
  transform: scale(1);
  transition: background-color 350ms ease, transform 350ms ease;
  appearance: none;
  -webkit-appearance: none;
  margin-top: 0;
}
.bpce-slider input[type=range]::-webkit-slider-runnable-track {
  background-color: #cccccc;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 2px;
}
.bpce-slider input[type=range]::-moz-range-track {
  background-color: #cccccc;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 2px;
}
.bpce-slider input[type=range]::-ms-track {
  background-color: #cccccc;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 2px;
  color: transparent;
  background-color: transparent;
  border: 0;
}
.bpce-slider input[type=range]::-ms-fill-lower {
  background-color: #cccccc;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 2px;
  background-color: #c8102e;
}
.bpce-slider input[type=range]::-ms-fill-upper {
  background-color: #cccccc;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 2px;
}
.bpce-slider input[type=range]::-ms-tooltip {
  display: none;
}
.bpce-slider input[type=range]:hover:not(:disabled)::-webkit-slider-thumb {
  transform: scale(1.5);
}
.bpce-slider input[type=range]:hover:not(:disabled)::-moz-range-thumb {
  transform: scale(1.5);
}
.bpce-slider input[type=range]:hover:not(:disabled)::-ms-thumb {
  transform: scale(1.5);
}
.bpce-slider input[type=range]:focus:not(:disabled) {
  outline: none;
}
.bpce-slider input[type=range]:focus:not(:disabled)::-moz-focus-outer {
  border: 0;
}
.bpce-slider input[type=range]:focus:not(:disabled)::-webkit-slider-thumb {
  transform: scale(1.5);
}
.bpce-slider input[type=range]:focus:not(:disabled)::-moz-range-thumb {
  transform: scale(1.5);
}
.bpce-slider input[type=range]:focus:not(:disabled)::-ms-thumb {
  transform: scale(1.5);
}
.bpce-slider input[type=range]:disabled::-webkit-slider-runnable-track {
  background-color: rgba(0, 0, 0, 0.08);
  cursor: default;
}
.bpce-slider input[type=range]:disabled::-moz-range-track {
  background-color: rgba(0, 0, 0, 0.08);
  cursor: default;
}
.bpce-slider input[type=range]:disabled::-ms-fill-upper {
  background-color: rgba(0, 0, 0, 0.08);
  cursor: default;
}
.bpce-slider input[type=range]:disabled::-webkit-slider-thumb {
  background-color: #999999;
  cursor: default;
}
.bpce-slider input[type=range]:disabled::-moz-range-thumb {
  background-color: #999999;
  cursor: default;
}
.bpce-slider input[type=range]:disabled::-ms-thumb {
  background-color: #999999;
  cursor: default;
}
.bpce-slider-progress {
  position: absolute;
  bottom: 4px;
  height: 4px;
  border-radius: 2px;
  background: #c8102e;
  pointer-events: none;
}
.bpce-slider-progress-thumb::before, .bpce-slider-progress-thumb::after {
  color: #c8102e;
}
input[type=range]:disabled + .bpce-slider-progress {
  background-color: #999999;
  cursor: default;
}
input[type=range]:disabled + .bpce-slider-progress-thumb::before, input[type=range]:disabled + .bpce-slider-progress-thumb::after {
  color: #333333;
}
.ie .bpce-slider-multi .bpce-slider-range-container::after {
  background-color: #cccccc;
  color: transparent;
  width: 100%;
  height: 4px;
  cursor: pointer;
  border-color: transparent;
  border-radius: 2px;
  background-color: #c8102e;
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -2px;
  width: 100%;
  z-index: 0;
}

div #ui-ink-bar {
  background-color: #c8102e;
}

.ui-tabs .ui-tab:hover, .ui-tabs .ui-tab:focus {
  color: #c8102e;
}
.ui-tabs .active-tab {
  color: #c8102e;
}

.bpce-tile-selected {
  border-color: #c8102e !important;
}
.bpce-tile-pro {
  border-color: #c8102e;
}
.bpce-tile-pro::after {
  background-color: #c8102e;
}

.ui-ripple {
  overflow: hidden;
  position: relative;
  z-index: -1;
}

.ui-ripple.ui-ripple-unbounded {
  overflow: visible;
}

.ui-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
  background-color: rgba(200, 16, 46, 0.1);
}

button.ui-button.outline .ui-ripple-element, button.ui-button.clear .ui-ripple-element {
  background-color: #cccccc;
}
button.ui-button.tertiary .ui-ripple-element, button.ui-button.quaternary .ui-ripple-element {
  background-color: transparent;
}

.primary-stroke {
  stroke: #c8102e;
}

.secondary-stroke {
  stroke: #ec6919;
}

.bpce-unavailablex-error-code {
  color: #c8102e;
}

table .filter-icon {
  color: #c8102e;
}