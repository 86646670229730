@import '../theming/themes/common-colors';

@mixin radio-theme($theme) {
  $primary: map-get($theme, primary);

  .bpce-radio {
    &-label:hover:before {
      border-color: $primary;
    }

    &-input:checked + &-label {
      &:before {
        border-color: $primary;
      }

      &:after {
        background-color: $primary;
      }
    }
  }
}
